.strike-thru {
  overflow: hidden;
  text-align: center;
}

.strike-thru:before,
.strike-thru:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.strike-thru:before {
  right: 0.5em;
  margin-left: -50%;
}

.strike-thru:after {
  left: 0.5em;
  margin-right: -50%;
}
